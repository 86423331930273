import {
  commonConstants,
  paymentConstants,
  destinationConstant,
} from '../constants';
import { paymentService, unitService, salesforceService } from '../services';
import { history } from '../history';
import { store } from '../store';
import { errorBlock } from '../api/errorBlock';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let executeOnce = 0;

const {
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  // GET_SUBSCRIPTION_ERROR,
  // GET_CUSTOMER_SUCCESS,
  // GET_CUSTOMER_FAILURE,
} = paymentConstants;
const { GET_CHANNEL_SERVICE_DES_SUCCESS } = destinationConstant;
export const lrtActions = {
  getAllSubscription,
  changeZone,
  salesforceValidateUnit,
  getCustomerId,
};

function getCustomerId() {
  return async (dispatch) => {
    //dispatch(request());
    return new Promise(async (resolve, reject) => {
      try {
        // const customer = await paymentService.getCustomer();
        // if (customer.length && customer[0].customer_id !== undefined) {
        //     dispatch(success(customer[0]));
        // dispatch(getAllSubscription(customer[0].customer_id));
        // resolve(customer);
        dispatch(getAllSubscription());
        resolve();
        // }else{
        //     dispatch(success({}));
        //     dispatch(sub_success([]));
        //     resolve(customer);
        // }
      } catch (err) {
        // dispatch(failure());
        errorBlock(err);
        reject(err);
      }
    });
  };

  // function success(customer) { return { type: GET_CUSTOMER_SUCCESS, customer } }
  // function sub_success(res) {
  //   return { type: GET_SUBSCRIPTION_SUCCESS, res };
  // }
  // function failure() { return { type: GET_CUSTOMER_FAILURE } }
}

// All studio subscription
function getAllSubscription(type) {
  return async (dispatch) => {
    dispatch(request());
    try {
      let res2 = [];
      if (type === 'trialing') {
        res2 = await await paymentService.getAllSubscription(type);
      }
      // const { customer_id } = store.getState().payment.customer;
      // if(customer_id){
      const res = await paymentService.getAllSubscription();
      dispatch(success([...res, ...res2]));
      //}
    } catch (err) {
      errorBlock(err);
    }
  };
  function request() {
    return { type: GET_SUBSCRIPTION_REQUEST };
  }
  function success(res) {
    return { type: GET_SUBSCRIPTION_SUCCESS, res };
  }
  // function failure(res) {
  //   return { type: GET_SUBSCRIPTION_ERROR, res };
  // }
}

function changeZone(id, selected_channel) {
  return async (dispatch) => {
    try {
      const payload = { unit: { selected_channel: `${selected_channel}` } };
      await unitService.changeZone(id, payload);
      ++executeOnce;
      setTimeout(() => {
        dispatch(getChannelAndDestination(id, selected_channel));
      }, 1000);
    } catch (err) {
      errorBlock(err);
    }
  };
}

function getChannelAndDestination(id, selected_channel) {
  return async (dispatch) => {
    //dispatch(request());
    try {
      const res = await unitService.getChannelServicesAndDestination(id);
      if (selected_channel === '') {
        if (!res.selected_channel) {
          dispatch(success(res));
        } else {
          executeOnce < 2
            ? dispatch(changeZone(id, ''))
            : dispatch(success(res));
        }
      } else {
        if (cookies.get(`selected_channel_${id}`) === res.selected_channel) {
          dispatch(success(res));
        } else {
          executeOnce < 2
            ? dispatch(changeZone(id, cookies.get(`selected_channel_${id}`)))
            : dispatch(success(res));
        }
      }
    } catch (err) {
      executeOnce = 0;
      notify('error', i18n.t('ZONENOTUPDATED'));
      errorBlock(err);
    }
  };
  function success(res) {
    executeOnce = 0;
    return { type: GET_CHANNEL_SERVICE_DES_SUCCESS, res };
  }
}

function salesforceValidateUnit(number) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      //const res = await salesforceService.salesforceAuth();
      //if (res.status) {
      const payload = { number };
      const { BOSSID } = store.getState().unit.unit;
      const valid_res = await salesforceService.salesforceValidateUnit(payload);
      dispatch(hideSpinner());
      if (valid_res.result === 'success') {
        if (!!valid_res.IsClaimed && valid_res.IsClaimed === 'no') {
          // if (!!valid_res.KitConfiguration) {
          if (
            valid_res.PartNumber === 'LU-SOLO-LRTC-KIT-02' ||
            valid_res.PartNumber === 'LU-SIMPACK-IOT-2'
          ) {
            history.push({
              pathname: `/dashboard/units/payment/soloconnect/${BOSSID}`,
              state: {
                scnumber: number,
                kc: '2',
                region: 'USA',
              },
            });
          } else if (valid_res.PartNumber === 'LU-SOLO-LRTC-KIT-02-EA') {
            // const region = valid_res.PartNumber.split('-')[5];
            history.push({
              pathname: `/dashboard/units/payment/soloconnect/${BOSSID}`,
              state: {
                scnumber: number,
                kc: '2',
                region: 'ROW',
              },
            });
          } else if (
            valid_res.PartNumber === 'LU-SOLO-LRTC-KIT-03' ||
            valid_res.PartNumber === 'LU-SIMPACK-IOT-3'
          ) {
            history.push({
              pathname: `/dashboard/units/payment/soloconnect/${BOSSID}`,
              state: {
                scnumber: number,
                kc: '3',
                region: 'USA',
              },
            });
          } else if (valid_res.PartNumber === 'LU-SOLO-KIT-04-BELT') {
            history.push({
              pathname: `/dashboard/units/payment/soloconnect/${BOSSID}`,
              state: {
                scnumber: number,
                kc: '4',
                region: 'USA',
              },
            });
          } else if (
            valid_res.PartNumber === 'LU-SOLO-LRTC-KIT-04-EA' ||
            valid_res.PartNumber === 'LU-SOLO-KIT-04-EA-BELT'
          ) {
            history.push({
              pathname: `/dashboard/units/payment/soloconnect/${BOSSID}`,
              state: {
                scnumber: number,
                kc: '4',
                region: 'ROW',
              },
            });
          } else {
            notify('info', i18n.t('NEWKITCONFIG'));
          }
          // } else {
          //   notify('warning', i18n.t('NOKITCONFIG'));
          //}
        } else {
          notify('info', i18n.t('SCNUMBERINUSE'));
        }
      } else {
        const message = i18n.t('NOTSOLOCONNECTSN').replace('NNNN', number);
        if (valid_res.result === 'not found') notify('info', message);
        else notify('error', i18n.t('ERROR'));
      }
      //dispatch(success(res))
      // }
    } catch (err) {
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
