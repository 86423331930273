import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const SelectSWVersion = (props) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { selectedSWversionId } = useSelector((state) => state.unit);
  const { unit, unitSn, swOptions } = props;

  const uniqueSwOptions = swOptions?.filter(
    (version, index, self) =>
      index ===
        self.findIndex((v) => v.versionNumber === version.versionNumber) &&
      version.versionNumber !== unit.sw_version,
  );

  const isVersionExist = uniqueSwOptions?.some(
    (option) => option.versionNumber === unit.sw_version,
  );
  const updatedSwOptions = !isVersionExist
    ? [
        ...uniqueSwOptions,
        {
          versionNumber: unit.sw_version,
          description: 'Online branch version',
          deprecated: null,
          releaseNotesUrl: null,
        },
      ]
    : uniqueSwOptions;

  useEffect(() => {
    dispatch(unitActions.storeSelectedSWVersionId(unit.sw_version));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit.sw_version]);

  const onChangeSWVersion = (val) => {
    if (val) {
      dispatch(unitActions.storeSelectedSWVersionId(val));
    }
  };

  return (
    <span className='editUnitName d-flex flex-row align-items-center mt-1'>
      <div className='smalltxt mb-1' style={{ whiteSpace: 'nowrap' }}>
        {unitSn} -
      </div>
      <div style={{ minWidth: '0' }}>
        <Select
          className='w-100'
          name='swVersion'
          value={selectedSWversionId}
          onChange={(value) => onChangeSWVersion(value)}
          onBlur={() => {
            dispatch(unitActions.onTouchSwVersionSelectField(true));
          }}
        >
          {updatedSwOptions.map(({ versionNumber }) => (
            <Select.Option
              value={versionNumber}
              key={versionNumber}
              disabled={unit.sw_version === versionNumber}
            >
              {versionNumber}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* <button
        type='button'
        className='btn btn-danger btn-sm btn-no mx-1 py-1 px-2'
        onClick={() => {
          props.cancelRenameUnit(unit);
          dispatch(unitActions.storeSelectedSWVersionId(null));
        }}
      >
        <i className='fa fa-times'></i>
      </button> */}
      <a
        href='https://solohelp.liveu.tv/hc/en-us/sections/13206843821851-Release-Notes'
        rel='noopener noreferrer'
        target='_blank'
        className='ml-2'
      >
        {i18n.t('RELEASENOTE')}
      </a>
    </span>
  );
};

export default SelectSWVersion;
