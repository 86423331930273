export const streamToolConstants = {
  GET_DSESTINATIONS_SUCCESS: 'GET_DSESTINATIONS_SUCCESS',
  GET_DSESTINATIONS_FAIL: 'GET_DSESTINATIONS_FAIL',
  SET_GOLIVE_STAGE: 'SET_GOLIVE_STAGE',
  CHANGE_STREAM_TOOL_MODE: 'CHANGE_STREAM_TOOL_MODE',
  SET_GOLIVE_INFO: 'SET_GOLIVE_INFO',
  SET_DND: 'SET_DND',

  SET_ST_INITIAL_STATE: 'SET_ST_INITIAL_STATE',
  EL_STREAM_TOKEN: 'EL_STREAM_TOKEN',
  EL_STREAM_ZONE: 'EL_STREAM_ZONE',
  EL_STREAM_LIVE_MANAGER_ID: 'EL_STREAM_LIVE_MANAGER_ID',
  EL_STREAM_INPUT_ID: 'EL_STREAM_INPUT_ID',
  EL_WATERMARK_URL: 'EL_WATERMARK_URL',
  EL_WATERMARK_X: 'EL_WATERMARK_X',
  EL_WATERMARK_Y: 'EL_WATERMARK_Y',
  EL_STREAM_SOCKET: 'EL_STREAM_SOCKET',
  EL_FALLBACK_SLATE_URL: 'EL_FALLBACK_SLATE_URL',
  EL_FALLBACK_SLATE_NAME: 'EL_FALLBACK_SLATE_NAME',
  EL_LOADING_DATA: 'EL_LOADING_DATA',
  EL_STREAMTOOLS_SUBSCRIBED: 'EL_STREAMTOOLS_SUBSCRIBED',
  EL_STREAMTOOLS_RESET: 'EL_STREAMTOOLS_RESET',
  EL_IS_STREAMTOOLS_STREAMING: 'EL_IS_STREAMTOOLS_STREAMING',
  UPDATE_ST_HELPER_DESTINATION: 'UPDATE_ST_HELPER_DESTINATION',
  SET_INITIAL_SET: 'SET_INITIAL_SET',
  SET_FPS: 'SET_FPS',
  SET_AUDIO_BITRATE: 'SET_AUDIO_BITRATE',
  SET_VIDEO_BITRATE: 'SET_VIDEO_BITRATE',
  SET_PROFILE: 'SET_PROFILE',
  SET_STEAMTOOL_ZONE: 'SET_STEAMTOOL_ZONE',
  SET_STREAMTOOL_ZONE_LOADER: 'SET_STREAMTOOL_ZONE_LOADER',
  SET_LOG_PROGRESS: 'SET_LOG_PROGRESS',
  SET_LOG_INTERVAL: 'SET_LOG_INTERVAL',
  EL_WATERMARK_ZOOM: 'EL_WATERMARK_ZOOM',
  INITIAL_DATA_LOADED: 'INITIAL_DATA_LOADED',
  LOGO_HEIGHT: 'LOGO_HEIGHT',
  LOGO_WIDTH: 'LOGO_WIDTH',
  SLATE_HEIGHT: 'SLATE_HEIGHT',
  SLATE_WIDTH: 'SLATE_WIDTH',
  STREAMTOOL_CLIENTID: 'STREAMTOOL_CLIENTID',
};
